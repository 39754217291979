<template>
    <div class="container">
        <BreadcrumbNav pageName="订单管理"></BreadcrumbNav>
        <div class="order_wrap auto">
            <div class="order_nav">
                <ul class="order_nav_list fl">
                    <li class="cur order_nav_list_active fl">全部</li>
                    <li class="cur fl">待支付</li>
                    <li class="cur fl">已支付</li>
                    <li class="cur fl">已退款</li>
                    <div class="clearfix"></div>
                </ul>
                <div class="order_nav_date fl">
                    <span class="fl">日期</span>
                    <div class="fl pick_date">
                        <el-date-picker
                                placeholder="选择日期"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                        <img alt="" src="../../assets/images/icon_date@2x.png">
                    </div>
                    <span class="fl">-</span>
                    <div class="fl pick_date">
                        <el-date-picker
                                placeholder="选择日期"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                        <img alt="" src="../../assets/images/icon_date@2x.png">
                    </div>
                    <div class="fl search_btn cur">查询</div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="order_title">
                <div class="fl order_title_id">
                    订单ID
                </div>
                <div class="fl order_title_date">
                    交易日期
                </div>
                <div class="fl order_title_state">
                    支付状态
                </div>
                <div class="fl order_title_price">
                    {{ $t("myWallet.amountUSD") }}
                </div>
                <div class="fl order_title_con">
                    {{ $t("myWallet.orderContent") }}
                </div>
                <div class="fr order_title_record">
                    共 <span>123</span> 条记录
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="order_list">
                <div class="order_item">
                    <div class="fl order_title_id">
                        20201208001
                    </div>
                    <div class="fl order_title_date">
                        08/12/2020
                    </div>
                    <div class="fl order_title_state">
                        待支付
                        <!--已支付-->
                    </div>
                    <div class="fl order_title_price">
                        $50.00
                    </div>
                    <div class="fl order_title_con">
                        3分钟访谈: Mr.A
                    </div>
                    <div class="fr order_title_record">
                        <div class="order_item_btn order_item_btn_one cur">
                            {{ $t("myWallet.payImmediately") }}
                            <!--      申请退款        -->
                            <!--      退款中        -->
                            <!--      已申请退款        -->

                        </div>
                        <!--            <span>退款申请失败不符合退款条件</span>-->
                        <!--            <span>退款申请成功</span>-->
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="order_item">
                    <div class="fl order_title_id">
                        20201208001
                    </div>
                    <div class="fl order_title_date">
                        08/12/2020
                    </div>
                    <div class="fl order_title_state">
                        待支付
                        <!--已支付-->
                    </div>
                    <div class="fl order_title_price">
                        $50.00
                    </div>
                    <div class="fl order_title_con">
                        3分钟访谈: Mr.A
                    </div>
                    <div class="fr order_title_record">
                        <div @click="isPop = true" class="order_item_btn order_item_btn_one cur">
                            <!--              立即支付-->
                            {{ $t("myWallet.applyForARefund") }}
                            <!--      退款中        -->
                            <!--      已申请退款        -->

                        </div>
                        <!--            <span>退款申请失败不符合退款条件</span>-->
                        <!--            <span>退款申请成功</span>-->
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="pop" v-if="isPop">
            <div class="pop_refund">
                <div class="pop_close">
                    <img @click="isPop = false" alt="" class="cur" src="../../assets/images/icon_closed@2x.png"
                         width="16px">
                </div>
                <div class="pop_title">
                    {{ $t("myWallet.applyForARefund") }}
                </div>
                <div class="pop_refund_nav auto">
                    <div class="pop_refund_nav_people fl">受邀者</div>
                    <div class="pop_refund_nav_id fl">订单编号</div>
                    <div class="pop_refund_nav_date fl">订单时间</div>
                    <div class="pop_refund_nav_plan fl">进度</div>
                    <div class="pop_refund_nav_price fl">{{ $t("myWallet.amountUSD") }}</div>
                    <div class="pop_refund_nav_state fl">{{ $t("bulletFrame.paymentStatus") }}</div>
                    <div class="clearfix"></div>
                </div>
                <div class="pop_refund_list">
                    <div class="pop_refund_item">
                        <div class="pop_refund_item_people fl">
                            <img alt="" class="fl" src="../../assets/images/1.png">
                            <div class="fl">
                                <h2>Andrew Norton</h2>
                                <p>阿里巴巴</p>
                            </div>
                        </div>
                        <div class="pop_refund_item_id fl">
                            20201208001
                        </div>
                        <div class="pop_refund_item_time fl">10/12/2020 09:30</div>
                        <div class="pop_refund_item_plan fl">访谈已结束</div>
                        <div class="pop_refund_item_price fl">$100</div>
                        <div class="pop_refund_item_state fl">支付成功</div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="pop_text">
                    <textarea :placeholder="$t('bulletFrame.refundReason')"></textarea>
                </div>
                <div class="cur pop_refund_btn auto">
                    {{ $t("myWallet.applyForARefund") }}
                </div>
                <div class="pop_refund_hint">
                    <h2>
                        {{ $t("bulletFrame.purchaseAndRefundInstructions") }}
                    </h2>
                    <ul>
                        <li>1.有价值的沟通话题是成功预约的基础，我们会审核您的话题，通过后帮助预约。</li>
                        <li>⒉.请您认真上传沟通话题需求，我们平台会赠送您VIP服务一个月。推荐大量优质人脉免费沟通最终帮助您解决您的问题。</li>
                        <li> 3.付款后一周平台人员会提前预约，预约成功后会通知您沟通时间，预约不成功会提前告知并退费。请上传您的名片或者简历。留意您的邮箱与短信。</li>
                        <li>4.沟通不依托平台的不予退款</li>
                        <li> 5.接听非本人退款</li>
                        <li>6.沟通满一分钟候不予退款</li>
                        <li>7.其他情况概不退款请谨慎预约”</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";

    export default {
        name: "orderManagement",
        data() {
            return {
                isPop: false
            }
        },
        components: {
            BreadcrumbNav,
        },
    }
</script>

<style>
    .container {
        width: 100%;
        min-height: 100%;
    }

    .order_wrap {
        width: 1200px;
        min-height: 78vh;
        padding-top: 31px;
    }

    .order_nav {
        margin-bottom: 28px;
        line-height: 40px;
    }

    .order_nav_list {

    }

    .order_nav_list li {
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        transition: .3s all;
        margin-right: 20px;
    }

    .order_nav_list li:nth-child(4) {
        margin-right: 0;
    }

    .order_nav_list_active {
        color: #FFFFFF !important;
        background: #000000;
    }

    .order_nav_list li:hover {
        color: #FFFFFF;
        background: #000000;
    }

    .el-date-editor.el-input {
        width: 145px;
        height: 40px;
    }

    .el-input__inner {
        background: #FAFAFA;
        border: none;
    }

    .el-icon-date:before {
        content: ''
    }

    .el-input--prefix .el-input__inner {
        padding-left: 0;
    }

    .order_nav_date {
        margin-left: 40px;
    }

    .order_nav_date span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        padding: 0 10px;
    }

    .pick_date {
        width: 207px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        padding-left: 20px;
        box-sizing: border-box;
    }

    .pick_date img {
        width: 20px;
    }

    .search_btn {
        width: 86px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
    }

    .order_title {
        width: 1200px;
        height: 47px;
        line-height: 47px;
        background: #FAFAFA;
        border-top: 1px solid #E5E5E5;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        text-align: center;
    }

    .order_title_id {
        width: 100px;
        text-align: left;
        padding-left: 17px;
        box-sizing: border-box;
    }

    .order_title_date {
        width: 150px;
        margin-left: 30px;
    }

    .order_title_state {
        width: 150px;
        margin-left: 20px;
    }

    .order_title_price {
        width: 150px;
        margin-left: 40px;
    }

    .order_title_con {
        width: 150px;
        margin-left: 100px;
    }

    .order_title_record {
        width: 200px;
        text-align: right;
        padding-right: 17px;
        box-sizing: border-box;
    }

    .order_list {
        margin-top: 22px;
    }

    .order_item {
        line-height: 32px;
        margin-bottom: 22px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
    }

    .order_item .order_title_id {
        padding-left: 0;
    }

    .order_item .order_title_record {
        text-align: center;
    }

    .order_item_btn {
        width: 99px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .order_item_btn_one {
        width: 99px;
        height: 32px;
        background: #FAFAFA !important;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        border: none !important;
    }

    .pop {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
        position: fixed;
        top: 0;
        left: 0;
    }

    .pop_refund {
        width: 1004px;
        height: 742px;
        background: #FFFFFF;
        border-radius: 8px;
        margin: 10% auto;
        padding: 25px;
        box-sizing: border-box;
    }

    .pop_close {
        text-align: right;
    }

    .pop_title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        text-align: center;
    }

    .pop_refund_nav {
        width: 937px;
        height: 47px;
        line-height: 47px;
        background: #FAFAFA;
        margin-top: 37px;
    }

    .pop_refund_nav div {
        width: 16.6%;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .pop_refund_nav_people {
        width: 20% !important;
    }

    .pop_refund_list {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .pop_refund_item > div {
        width: 16.6%;
    }

    .pop_refund_item_people {
        width: 20% !important;
    }

    .pop_refund_nav_state {
        width: 12% !important;
    }

    .pop_refund_item_state {
        width: 12% !important;
    }

    .pop_refund_item_people img {
        width: 49px;
        height: 49px;
        border-radius: 50%;
    }

    .pop_refund_item_people div {
        margin-left: 12px;
    }

    .pop_refund_item_people h2 {
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
        margin-top: 4px;
    }

    .pop_refund_item_people p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 5px;
    }

    .pop_refund_item_id, .pop_refund_item_time, .pop_refund_item_plan, .pop_refund_item_price, .pop_refund_item_state {
        text-align: center;
        line-height: 49px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .pop_refund_item_state {
        width: 10% !important;
    }

    .pop_text {
        width: 937px;
        height: 133px;
        background: #FAFAFA;
        border-radius: 4px;
    }

    .pop_text textarea {
        width: 937px;
        height: 133px;
        padding: 15px 26px;
        box-sizing: border-box;
        background: #FAFAFA;
        border-radius: 4px;
    }

    .pop_refund_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 48px;
        text-align: center;
        margin-top: 37px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .pop_refund_hint {
        margin: 22px auto 0;
        width: 800px;
        height: 186px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        opacity: 1;
    }

    .pop_refund_hint h2 {
        font-size: 14px;
    }

    .pop_refund_hint ul {
        width: 800px;
    }
</style>
